@media (max-width: 1135px) {
  .rules-photos {
    flex-direction: column;
    width: auto !important;
    align-items: center;
  }

  .rules-photos img {
    width: 8em;
  }
}
