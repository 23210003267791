.AdminLogin .content {
  margin-top: 3em !important;
}

.AdminLoginForm {
  margin-top: 3em;
  text-align: center;
}

.AdminLoginForm form input {
  width: 300px;
  height: 50px;
  padding: 0.5em;
}

.AdminLoginForm form button {
  padding: 0.8em;
  width: 200px;
  cursor: pointer;
}
