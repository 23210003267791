.Root .content {
  padding: 0 7em 0 7em;
}

.Root .cards {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 70%;
}

.Root .card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 1.5em;
  width: 270px;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.Root .card p {
  line-height: 1.8;
}

.Root .card h3 {
  color: var(--dark-green);
}

.Root .card .circle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  width: 120px;
  height: 120px;
  border: 2px solid var(--light-green);
  border-radius: 50%;
}

.Root .countdown {
  text-align: center;
  font-size: 50px;
  margin-bottom: 2em;
}

.Root .countdown span {
  border-bottom: 2px solid var(--light-green);
}

.swiper {
  width: 100%;
  height: 100%;
  margin-bottom: 3em;
  z-index: 0 !important;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  flex-direction: column;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider {
  margin: 0 auto;
  height: auto !important;
  width: 70%;
}

.slider img {
  height: 29em;
  width: auto;
}

.swiper-slide {
  height: auto !important;
}

.Root .banner {
  display: block;
  max-height: 24em;
  margin: 0 auto;
  margin-bottom: 3em;
}

@media (max-width: 1395px) {
  .Root .content {
    padding: 0 2em 0 2em;
  }
}

@media (max-width: 1400px) {
  .Root .cards {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Root .card {
    margin-bottom: 2.5em;
  }
  .Root .banner {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1500px) {
  .Root .content {
    padding: 0 1em 0 1em;
  }

  .Root .cards {
    width: 90%;
  }
}

@media (max-width: 1005px) {
  .Root .content {
    padding: 0 0.5em 0 0.5em;
  }

  .slider img {
    height: auto;
    width: 100%;
  }
}
