.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 25px;
}

.gallery__img {
  /* width: 100%; */
  /* height: 100%; */
  /* object-fit: cover; */
  width: 80%;
  cursor: pointer;
  border: 14px solid darkred;
  border-radius: 7px;
}

.jury_photo {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.JurySectionContent .ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index: 2;
}

.JurySectionContent .ReactModal__Content {
  background: var(--dark-green) !important;
  border: none !important;
}

.JurySectionContent .modal-image-wrapper {
  position: relative;
  text-align: center;
  color: white;
}

.modal-image-wrapper .title {
  color: #fff;
  font-size: 25px;
}

.jury-img {
  height: 35vw !important;
  max-width: 100% !important; /* Fixed 24 Sept., 2023 */
}

.grades {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
}

.grades span {
  color: #fff;
  font-weight: 700;
  font-size: 25px;
  cursor: pointer;
}

.grades span:hover {
  color: rgb(15, 241, 15) !important;
  /* color: rgb(187, 44, 44); */
}

.next-prev {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
}

.next-prev span {
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}

.next-prev span:hover {
  color: rgb(15, 241, 15);
}
