.SectionsContent {
  margin-top: 3em;
  padding: 0 3em 0 3em;
}

.SectionsContent p {
  font-size: 18px;
}

.SectionsContent form,
.EntryContent form {
  text-align: left;
}

.SectionsContent h3 {
  margin-top: 4em;
}

.SectionsContent a,
.SectionsContent p,
.SectionsContent h3 {
  text-align: left;
}

.SectionsContent a {
  font-weight: bold;
}

.SectionsContent a:hover {
  color: var(--light-green);
}
