.SectionContent .delete-button {
  cursor: pointer;
}

.SectionContent .delete-button:hover {
  color: var(--light-green);
}

.AdminPanel {
  padding-left: 1.5em;
  padding-right: 1.5em;
}
