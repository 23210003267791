.contact-form {
  /* margin-top: 3em; */
}

.contact-form h2 {
  margin-bottom: 1.5em;
}

.contact-form .details {
  text-align: center;
  width: 50%;
  margin: 0 auto;
  padding-top: 2em;

  line-height: 1.7;
}

.contact-form textarea {
  padding: 0 !important;
}

.contact-form .form-container {
  display: flex;
  justify-content: center;
  width: 50%;
  margin: 0 auto;
}

.contact-form .contact-form {
  margin-top: 3em;
  display: block;
  width: 100%;
}

.contact-form .contact-form input,
.contact-form .contact-form textarea {
  display: block;
  margin-top: 5em;
  width: 300px;
  border: none;
  border-bottom: 1px solid black;
  padding-bottom: 0.7em;
  transition: 0.2s;
  font-size: 14px;
}

.contact-form .contact-form input:focus,
.contact-form .contact-form textarea:focus {
  outline: none;
}

.contact-form .contact-form input:focus,
.contact-form .contact-form textarea:focus {
  border-color: var(--light-green);
}

.contact-form .contact-form .long {
  width: 100%;
}

.contact-form .contact-form .row {
  display: flex;
  justify-content: space-between;
}

.contact-form .contact-form .submit-button {
  margin-top: 6em;
  border: none;
  background: transparent;
  border-bottom: 1px solid var(--dark-green);
  width: 80px;
  padding-bottom: 0.7em;
  color: var(--light-green);
  cursor: pointer;
  transition: 0.2s;
}

.contact-form .contact-form .submit-button:focus {
  outline: none;
}

.contact-form .contact-form .submit-button:hover {
  width: 100px;
  color: var(--dark-green);
  border-color: var(--light-green);
}

@media (max-width: 995px) {
  .contact-form .contact-form input {
    width: 250px;
  }
}

@media (max-width: 860px) {
  .contact-form .details,
  .contact-form .form-container {
    width: 80%;
  }
}

@media (max-width: 730px) {
  .contact-form .contact-form input {
    width: 180px;
  }
}

@media (max-width: 650px) {
  .contact-form .details,
  .contact-form .form-container {
    width: 90%;
  }
}

@media (max-width: 539px) {
  .contact-form .details,
  .contact-form .form-container {
    width: 95%;
  }

  .contact-form .contact-form input {
    width: 150px;
  }
}

@media (max-width: 376px) {
  .contact-form .contact-form input {
    width: 130px;
  }
}

@media (max-width: 320px) {
  .contact-form .contact-form input {
    width: 105px;
  }
}
