.AdminPanelContent {
  margin-top: 3em;
  padding: 0 3em 0 3em;
}

.AdminPanelContent p,
.AdminPanelContent a,
.SectionsContent a,
.SectionsContent p {
  color: #000;
  cursor: pointer;
  display: block;
  margin-top: 2em !important;
  font-size: 22px;
}

.AdminPanelContent p:hover,
.AdminPanelContent a:hover {
  color: var(--light-green);
}
