.toolbar {
  /* position: fixed; */

  /* background: var(--dark-green);
  border-bottom: 16px solid var(--light-green); */
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 1;
  transition: 0.3s;
}

.toolbar.scrolled {
  background: var(--dark-green) !important;
  border-bottom: 16px solid var(--light-green) !important;
}

.non-root-toolbar {
  background: var(--dark-green);
}

.toolbar.scrolled .inner-toolbar {
  height: 90px;
}

.toolbar.scrolled .logo {
  height: 4em;
}

.root-toolbar {
  background: transparent !important;
  border-bottom: none;
}

.inner-toolbar {
  transition: 0.3s;
  top: 0;
  left: 0;
  width: 90%;
  height: 120px;

  padding-top: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toolbar .spacer-container {
  width: 33%;
  display: flex;
  justify-content: center;
}

.toolbar .title {
  color: #fff;
  margin: 0;
  font-size: 26px;
  margin-right: 3em;
}

.toolbar .logo {
  height: 6em;
  display: block;
  animation: spin 4s infinite;
  transition: 0.3s;
}

.spacer-container-logo {
  padding-bottom: 0.2em;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.toolbar-toggle-button span {
  margin-left: 0.5em;
}

.toolbar-toggle-button {
  display: flex;
  align-items: center;
  font-size: 23px;
}

.toolbar-logo {
  height: 100%;
  font-size: 2.5em;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
}

.toolbar-navigation {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: #fff;
  font-weight: 400;
}

.toolbar-auth {
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spacer-container.spacer-container-center {
  align-self: flex-end;
  margin-bottom: 2em;
}

.toolbar-navigation-items ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.toolbar-navigation-items li {
  padding: 0 0.5rem;
}

.toolbar-navigation-items span,
.toolbar-navigation-items a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  margin-left: 1em;
  margin-right: 1em;
  cursor: pointer;
  font-size: 15px;
}

.toolbar-navigation-items a:hover,
.toolbar-navigation-items a:active {
  color: var(--light-green);
}

.toolbar .spacer {
  display: none;
}

@media (max-width: 1300px) {
  .toolbar .spacer-container {
    display: flex;
    justify-content: center;
  }

  .toolbar .title {
    text-align: center;
  }

  .toolbar-navigation-items {
    display: none;
  }

  .toolbar .spacer-container.hamburger-spacer {
    justify-content: flex-start;
  }

  .spacer-container h1 {
    font-size: 20px;
  }

  .toolbar .logo {
    height: 4em !important;
  }

  .spacer-container.spacer-container-center {
    display: none;
  }

  .toolbar .title-spacer {
    justify-content: center;
  }

  .toolbar .spacer-container-logo {
    justify-content: flex-end;
  }

  .inner-toolbar {
    height: 180px;
  }
}

.toolbar h1 {
  width: 100%;
}

@media (max-width: 615px) {
  .spacer-container h1 {
    font-size: 16px;
  }

  .toolbar .logo {
    height: 3em !important;
  }
}

@media (max-width: 560px) {
  .spacer-container h1 {
    font-size: 13px;
  }

  .toolbar .logo {
    height: 3em !important;
  }

  .inner-toolbar {
    height: 140px;
  }
}

@media (min-width: 1391px) {
  .toolbar-navigation-items {
    margin-left: 5em;
  }
  .logo {
    margin-left: 7em;
  }

  .toolbar-navigation-items a {
    font-size: 13px !important;
  }

  .toolbar .title {
    /* height: 14px !important; */
    margin-left: 1em;
  }

  .toolbar {
    justify-content: space-between !important;
  }

  .inner-toolbar {
    width: 100%;
    justify-content: space-between !important;
  }

  .toolbar .spacer-container.hamburger-spacer {
    display: none;
    justify-content: flex-start;
  }

  .toolbar-toggle-button {
    display: none;
  }
}

@media (max-width: 540px) {
  .toolbar .hamburger-title {
    display: none;
  }
}

@media (max-width: 370px) {
  .toolbar .social {
    display: none;
  }
}

@media (max-width: 1270px) and (min-width: 1151px) {
  .inner-toolbar {
    width: 100%;
  }
}

@media (max-width: 765px) {
  .toolbar .title {
    font-size: 21px;
  }

  .toolbar .title-spacer {
    width: 50%;
  }
}

@media (max-width: 620px) {
  .toolbar .title {
    font-size: 15px;
    /* margin-left: 1em; */
  }
}

@media (max-width: 450px) {
  .toolbar .title {
    font-size: 15px;
  }
}
