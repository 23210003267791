.Table {
  padding: 0 5em 0 5em;
  overflow-x: auto;
}

.Table table {
  margin-top: 3.5em;
  width: 100%;
}

.Table tr.first {
  background: var(--light-green) !important;
  color: #fff;
}

.Table tr {
  background: #e2e2e2;
}

.Table tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.Table th {
  padding: 0.5em 0.7em 0.5em 0.7em;
}

@media (max-width: 1150px) {
  .Table {
    padding: 0 0.5em 0 0.5em;
    overflow-x: auto;
  }
}
