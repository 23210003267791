/* .contact-form {
  margin-top: 3em;
} */

.contact-form h2 {
  margin-bottom: 1.5em;
}

.registration-titles::placeholder {
  font-size: 10px;
}

.registration-form .details {
  text-align: center;
  width: 50%;
  margin: 0 auto;
  /* padding-top: 12em; */

  line-height: 1.7;
}

.registration-form .h3-right {
  text-align: right;
}

.registration-form .col {
  /* width: 50%; */
  width: 100%;
}

.registration-form .personal-data {
  display: flex;
  justify-content: space-between;
}

.registration-form .form-container {
  /* display: flex;
  justify-content: center; */
  width: 55%;
  margin: 0 auto;
}

.registration-form .registration-form {

  display: block;
  width: 100%;
}

.registration-form .registration-form select,
.Payment select,
.registration-form .registration-form input,
.Payment input,
.registration-form .registration-form textarea {
  display: block;
  margin-bottom: 5em;
  width: 300px;
  border: none;
  border-bottom: 1px solid black;
  padding-bottom: 0.7em;
  transition: 0.2s;
  font-size: 14px;
}

.registration-form .registration-form select:focus,
.Payment select:focus {
  outline: none;
}

.registration-form .registration-form input[type='checkbox'] {
  padding: 0;
  margin: 0;
  display: inline;
  width: auto !important;
}

.registration-form input[type='file'] {
  padding-bottom: 0.5em;
  display: inline;
}

.registration-form .file-input-container {
  display: flex;
  justify-content: space-between;
}

.registration-form .file-input-container span {
  margin-right: 1em;
  font-size: 18px;
  font-weight: 700;
  color: var(--light-green);
}

.registration-form .registration-form input:focus,
.Payment input:focus,
.registration-form .registration-form textarea:focus {
  outline: none;
}

.registration-form .registration-form select:focus,
.Payment input:focus,
.registration-form .registration-form input:focus,
.registration-form .registration-form textarea:focus {
  border-color: var(--light-green);
}

.registration-form .registration-form .long {
  width: 100%;
}

.registration-form .registration-form .row {
  display: flex;
  justify-content: space-between;
}

.submit-button.first {
  margin-top: 5.5em !important;
}

.registration-form .registration-form .submit-button {
  margin-top: 0em;
  border: none;
  background: transparent;
  border-bottom: 1px solid var(--dark-green);
  width: 150px;
  padding-bottom: 0.7em;
  color: var(--light-green);
  cursor: pointer;
  transition: 0.2s;
}

.registration-form .registration-form .submit-button:focus {
  outline: none;
}

.registration-form .registration-form .submit-button:hover {
  width: 160px;
  color: var(--dark-green);
  border-color: var(--light-green);
}

.registration-form .uploads {
  margin-top: 2em;
  display: flex;
  justify-content: center;
}

.registration-form .upload-section input {
  width: 220px;
}

.registration-form input[type='file'] {
  margin-bottom: 2.5em;
}

@media (max-width: 1285px) {
  .registration-form .form-container {
    width: 100%;
  }
}

@media (max-width: 1065px) {
  .registration-form .uploads {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 995px) {
  .registration-form .registration-form select,
  .registration-form .registration-form input {
    width: 250px;
  }

  .registration-form .form-container {
    width: 100%;
  }
}

@media (max-width: 860px) {
  .registration-form .details,
  .registration-form .form-container {
    width: 100%;
  }
}

@media (max-width: 765px) {
  .registration-form .col {
    width: 100%;
  }
  .registration-form .form-container {
    flex-direction: column;

    align-items: center;
  }

  .registration-form .file-input-container {
    flex-direction: column;
  }

  .registration-form .file-input-container span {
    display: block;
    margin-bottom: 1em;
    margin-right: 0;
  }

  .registration-form .rules {
    font-size: 13.5px;
  }
}

@media (max-width: 650px) {
  .registration-form .details,
  .registration-form .form-container {
    width: 100%;
  }
}

@media (max-width: 539px) {
  .registration-form .details,
  .registration-form .form-container {
    width: 100%;
  }
}

@media (min-width: 1441px) {
  .registration-form .form-container {
    width: 100%;
  }

  .registration-form .personal-data {
    width: 45%;
    margin: 0 auto;
  }
}

@media (max-width: 810px) {
  .registration-form .personal-data {
    flex-direction: column;
    align-items: center;
  }

  .registration-form .button-one {
    display: none;
  }
}

@media (min-width: 811px) {
  .registration-form .button-two {
    display: none;
  }
}
