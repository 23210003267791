.Results {
  padding: 0 5em 0 5em;
}

.Results h2 {
  font-size: 32px;
  text-align: left;
  margin-bottom: 1em;
}

.Results .titles {
  margin-bottom: 2em;
}

.Results .titles p {
  line-height: 1.7;
}

.Results .slide img {
  height: 15em !important;
  cursor: pointer;
  /* width: auto !important; */
}

/* .Results .slide {
  display: flex;
  justify-content: center;
} */

.Results .swiper-slide {
  /* width: auto !important; */
  /* flex-shrink: 100 !important; */
  height: 15em;

}

.Results .container {
  /* width: 50%; */
  margin: 0 auto;
}

.Results .mySwiper {
  z-index: 0;
  margin-bottom: 2em;
}

.swiper-button-prev,
.swiper-button-next {
  color: var(--light-green) !important;
}

.swiper-pagination-bullet-active {
  background: var(--light-green) !important;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index: 2;
}

.ReactModal__Content {
  background: var(--light-green) !important;
  border: none !important;
}

.modal-image-container {
  position: relative;
  text-align: center;
  color: white;
}

.modal-image-container .text {
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.75) !important;
  padding: 0.2em 0 0.3em 0;
  width: 100%;
}

.modal-image-container img {
  max-height: 78vh;
  width: 100%;
}

@media (max-width: 815px) {
  .Results {
    padding: 0 1.5em 0 1.5em;
  }
}
