.EntryContent {
  padding-bottom: 3em;
}

.entry-details h3 {
  margin-top: 1em;
}

.EntryContent img {
  height: 14em;
  display: block;
  /* margin-top: 1.5em; */
}

.EntryContent .image-container {
  padding: 0.7em;
  border: 2px solid var(--light-green);
  display: inline-block;
}

.EntryContent .image-container p {
  margin-top: 0.7em !important;
}

.EntryContent p:hover {
  color: black;
}
